import { useEffect, useState } from "react";

const IsOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  let observer = null;

  if (typeof window !== "undefined") {
    observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
  }

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
};

export default IsOnScreen;
