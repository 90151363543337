import { useState, useRef } from "react";

import rightArrow from "./img/rightArrow.svg";
import rightArrowWhite from "./img/rightArrowWhite.svg";

import yesOpen from "./img/tim-mossholder-sxb8StmTfaw-unsplash.jpg";

import NumberUpAnimation from "./NumberUpAnimation";
import IsOnScreen from "./IsOnScreen";

import oppai from "./img/oppai.png";

import oppCircle from "./img/OppCircle.png";
import foodStock from "./img/food-stock-warehouse.png";
import workers from "./img/workers-moving-boxes.png";
import modernSpace from "./img/modern-space.png";
import "./App.scss";
import Bridgestone from "./img/logos/image1.png";
import Firestone from "./img/logos/image2.png";
import PremierHealth from "./img/logos/image3.png";
import LandsEnd from "./img/logos/image4.png";
import CMD from "./img/logos/image5.png";
import Dole from "./img/logos/image6.png";
import Ahold from "./img/logos/image7.png";
import Echo from "./img/logos/image8.png";
import TiresPlus from "./img/logos/image14.png";
import Sutherland from "./img/logos/image15.png";
import USFoods from "./img/logos/image16.png";
import GeneralGrowthProperties from "./img/logos/image17.png";
import Singtel from "./img/logos/image18.png";

import TopBlueSwoosh from "./img/TopBlueSwoosh.svg";
import BottomBlueSwoosh from "./img/BottomBlueSwoosh.svg";
import TopYellowSwoosh from "./img/TopYellowSwoosh.svg";
import BottomYellowSwoosh from "./img/BottomYellowSwoosh.svg";
import TopRedSwoosh from "./img/TopRedSwoosh.svg";
import BottomRedSwoosh from "./img/BottomRedSwoosh.svg";

function App() {
  const [formInfo, setFormInfo] = useState({
    firstName: "",
    lastName: "",
    companyRole: "",
    directDial: "",
    email: "",
    message: "",
    "form-name": "tiex",
  });

  const ref = useRef();
  const isVisible = IsOnScreen(ref);

  const handleFormUpdate = (e) => {
    setFormInfo({
      ...formInfo,
      [e.target.name]: e.target.value,
    });
  };

  const logos = [
    { name: Bridgestone, alt: "Bridgestone" },
    { name: Firestone, alt: "Firestone" },
    { name: PremierHealth, alt: "Premier Health" },
    { name: LandsEnd, alt: "Land's End" },
    { name: CMD, alt: "CMD" },
    { name: Dole, alt: "Dole" },
    { name: Ahold, alt: "Ahold" },
    { name: Echo, alt: "Echo" },
    { name: TiresPlus, alt: "Tires Plus" },
    { name: Sutherland, alt: "Sutherland" },
    { name: USFoods, alt: "US Foods" },
    { name: GeneralGrowthProperties, alt: "General Growth Properties" },
    { name: Singtel, alt: "Singtel" },
    { name: Bridgestone, alt: "Bridgestone" },
    { name: Firestone, alt: "Firestone" },
    { name: PremierHealth, alt: "Premier Health" },
    { name: LandsEnd, alt: "Land's End" },
    { name: CMD, alt: "CMD" },
    { name: Dole, alt: "Dole" },
    { name: Ahold, alt: "Ahold" },
    { name: Echo, alt: "Echo" },
    { name: TiresPlus, alt: "Tires Plus" },
    { name: Sutherland, alt: "Sutherland" },
    { name: USFoods, alt: "US Foods" },
    { name: GeneralGrowthProperties, alt: "General Growth Properties" },
    { name: Singtel, alt: "Singtel" },
  ];

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   fetch("/", {
  //     body: formInfo,
  //     method: "POST",
  //   })
  //     .then(() => {
  //       console.log("Success!");
  //     })
  //     .catch((error) => {
  //       console.log(`Failed: ${error}`);
  //     });
  // };

  return (
    <div className="App">
      <header>
        <div className="wrapper">
          <h1>
            <img src={oppai} alt="Opportunity AI" />
          </h1>
        </div>
        <div className="header-container">
          <div className="info">
            <div className="header-xl">
              WE SEE MOVES WHERE OTHERS SEE TRENDS
            </div>
            <img
              src={oppCircle}
              alt="Opportunity AI App Example"
              className="product-phone-mobile circle-opp"
            />
            <p className="header-s">
              Only with the power of AI can obscured patterns be seen.
            </p>
            <p className="header-m">SEE WHAT YOUR OPPORTUNITY.AI LOOKS LIKE.</p>

            <a href="#demo" className="grow">
              <span>GET EARLY ACCESS</span>
              <img aria-hidden="true" src={rightArrow} alt="arrow" />
            </a>
          </div>
          <img
            src={oppCircle}
            alt="Opportunity AI App Example"
            className="product-phone-desk"
          />
        </div>
      </header>
      <section id="big-questions">
        <h2 className="header-xl">
          How Do You Answer Big Questions? <br />
          <span className="blue header-l">Start with Small Ones</span>
        </h2>
        <div className="content-card">
          <img src={yesOpen} alt="Small Business Open Sign" />
          <div className="info">
            <h3 className="header-m">Get Closer To Your Customers</h3>
            <p>
              Topline revenue is easy to evaluate. But that's only part of the
              view. Opportunity.AI goes straight to the source crafting
              intelligence closest to your customer; allowing you to better
              satisfy demand.
            </p>
            <p className="body-bold">
              It’s there where we show you the opportunity.
            </p>
          </div>
        </div>
      </section>
      <div className="blue-section-start" aria-hidden="true">
        <img src={TopBlueSwoosh} alt="" />
      </div>
      <section id="opportunity">
        <div className="circle-header-container">
          <div className="circle-w-line">
            <div className="line" />
            <div className="circle" />
          </div>
          <div className="yep">
            <h2 className="header-l">
              Opportunity.AI focuses your business lens so you can drive real
              business impact.
            </h2>
            <p>
              Whether your impact goals are new distribution, trial, frequency
              or volume - Opportunity.AI helps you be a C-Suite hero.
            </p>
          </div>
        </div>
      </section>
      <div className="blue-section-end">
        <img src={BottomBlueSwoosh} alt="" />
      </div>
      <section id="stats" ref={ref}>
        <div className="wrapper column">
          <h2 className="header-l">The Numbers Don't Lie</h2>
          {isVisible && (
            <dl className="stats" target="_blank" rel="noopener noreferrer">
              <div>
                <a
                  href="https://intelligenceexchange.ai/how-businesses-are-implementing-intelligence-and-using-data-driven-industrial-software/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <dd>{<NumberUpAnimation>2</NumberUpAnimation>}x</dd>
                  <dt>MARKET CAPITALIZATION</dt>
                </a>
              </div>
              <div>
                <a
                  href="https://intelligenceexchange.ai/how-businesses-are-implementing-intelligence-and-using-data-driven-industrial-software/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <dd>+{<NumberUpAnimation>4</NumberUpAnimation>}%</dd>
                  <dt>DIGITAL 5-YEAR OI LIFT</dt>
                </a>
              </div>
              <div>
                <a
                  href="https://intelligenceexchange.ai/how-businesses-are-implementing-intelligence-and-using-data-driven-industrial-software/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <dd>{<NumberUpAnimation>11</NumberUpAnimation>}%</dd>
                  <dt>REDUCED COSTS</dt>
                </a>
              </div>
              <div>
                <a
                  href="https://intelligenceexchange.ai/how-businesses-are-implementing-intelligence-and-using-data-driven-industrial-software/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <dd>{<NumberUpAnimation>15</NumberUpAnimation>}%</dd>
                  <dt>EFFICIENCY & PRODUCTIVITY</dt>
                </a>
              </div>
              <div>
                <a
                  href="https://intelligenceexchange.ai/how-businesses-are-implementing-intelligence-and-using-data-driven-industrial-software/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <dd>{<NumberUpAnimation>+37</NumberUpAnimation>}%</dd>
                  <dt>REVENUE PER EMPLOYEE</dt>
                </a>
              </div>
            </dl>
          )}
          <a href="#demo" className="grow link">
            GET MOVING
            <img aria-hidden="true" src={rightArrowWhite} alt="arrow" />
          </a>
        </div>
      </section>
      <div className="yellow-section-start" aria-hidden="true">
        <img src={TopYellowSwoosh} alt="" />
      </div>
      <section id="three-in-one">
        <div className="circle-header-container">
          <div className="circle-w-line">
            <div className="line" />
            <div className="circle" />
          </div>
          <h2 className="header-l">A SYSTEM THAT SEES YOUR NEXT MOVE</h2>
        </div>
        <ul className="products">
          <li>
            <img src={foodStock} alt="Food Stock Warehouse" />
            <h3 className="header-l yellow">Opportunity</h3>
            <p className="description">
              Opportunity brings real potential into focus - local customers,
              markets and products. You can see whitespace clearly for the first
              time.
            </p>
          </li>
          <li>
            <img src={workers} alt="Food Stock Warehouse" />
            <h3 className="header-l yellow">Target</h3>
            <p className="description">
              Target prioritizes your moves based on your sales and marketing
              priorities. Track your actions; know what matters.
            </p>
          </li>
          <li>
            <img src={modernSpace} alt="Food Stock Warehouse" />
            <h3 className="header-l yellow">Impact</h3>
            <p className="description">
              Impact measures what matters - revenue. The more actions you take
              the smarter the system and the greater the impact.
            </p>
          </li>
        </ul>
      </section>
      <div className="yellow-section-end">
        <img src={BottomYellowSwoosh} alt="" />
      </div>
      <section id="how">
        <div className="circle-header-container">
          <div className="circle-w-line">
            <div className="line" />
            <div className="circle" />
          </div>
          <div className="yep">
            <h2 className="header-l">How It Works</h2>
            <p className="about">
              Opportunity.AI lets you see obscured correlations to identify
              unseen demand.
            </p>
          </div>
        </div>
        <ul className="how-it-works">
          <div className="wrapper">
            <div className="top">
              <li>
                <div className="info">
                  <h3>See</h3>
                  <p>
                    Talk with one of our experts to see Opportunity.AI and learn
                    how your business might be missing out on valuable
                    opportunities.
                  </p>
                </div>
                <div className="circle" />
              </li>
              <li>
                <div className="info">
                  <h3>Move</h3>
                  <p>
                    Insights are only as valuable as their impact. So our system
                    provides direction on which actions may produce the desired
                    impact, empowering the operator to make smarter decisions.
                  </p>
                </div>
                <div className="circle" />
              </li>
            </div>
          </div>
          <div className="full-width-wrapper">
            <div className="wrapper">
              <div className="bottom">
                <li>
                  <div className="circle" />
                  <div className="info">
                    <h3>Focus</h3>
                    <p>
                      We manage a complex system of signals that reveal the
                      undiscovered opportunity for your business, based on your
                      unique goals.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="circle" />
                  <div className="info">
                    <h3>Learn</h3>
                    <p>
                      After measuring the impact of your actions, the system
                      identifies wins, fails and unknowns - bundling these
                      learnings with fresh inputs.
                    </p>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </ul>
        <ul className="how-it-works-mobile">
          <div className="wrapper">
            <div className="top">
              <li>
                <div className="info">
                  <h3>Focus</h3>
                  <p>
                    We manage a complex system of signals that reveal the
                    undiscovered opportunity for your business, based on your
                    unique goals.
                  </p>
                </div>
                <div className="circle" />
              </li>
              <li>
                <div className="info">
                  <h3>See</h3>
                  <p>
                    Talk with one of our experts to see Opportunity.AI and learn
                    how your business might be missing out on valuable
                    opportunities.
                  </p>
                </div>
                <div className="circle" />
              </li>
            </div>
          </div>
          <div className="full-width-wrapper">
            <div className="wrapper">
              <div className="bottom">
                <li>
                  <div className="circle" />
                  <div className="info">
                    <h3>Learn</h3>
                    <p>
                      After measuring the impact of your actions, the system
                      identifies wins, fails and unknowns - bundling these
                      learnings with fresh inputs.
                    </p>
                  </div>
                </li>

                <li>
                  <div className="circle" />
                  <div className="info">
                    <h3>Move</h3>
                    <p>
                      Insights are only as valuable as their impact. So our
                      system provides direction on which actions may produce the
                      desired impact, empowering the operator to make smarter
                      decisions.
                    </p>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </ul>
      </section>

      <section id="logos">
        <div className="circle-header-container">
          <div className="circle-w-line">
            <div className="line" />
            <div className="circle" />
          </div>
          <h2 className="header-l">Who We Work With</h2>
        </div>
        <div className="slider white-gradient">
          <div className="slide-track">
            {logos.map((logo, idx) => {
              return (
                <div className="slide" key={idx}>
                  <img
                    src={logo.name}
                    width="125%"
                    height="auto"
                    alt={logo.alt}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="red-section-start" aria-hidden="true">
        <img src={TopRedSwoosh} alt="" />
      </div>
      <section id="demo">
        <div className="wrapper">
          <div className="info">
            <h2 className="header-l">
              BEGIN YOUR SMART MOVES WITH OPPORTUNITY.AI
            </h2>

            <form
              // onSubmit={handleSubmit}
              netlify
              method="POST"
              name="tiex"
            >
              <label htmlFor="firstName">
                First Name
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={formInfo.firstName}
                  onChange={handleFormUpdate}
                />
              </label>
              <label htmlFor="lastName">
                Last Name
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={formInfo.lastName}
                  onChange={handleFormUpdate}
                />
              </label>
              <label htmlFor="companyRole">
                Company Role
                <input
                  type="text"
                  name="companyRole"
                  id="companyRole"
                  placeholder="Company Role"
                  value={formInfo.companyRole}
                  onChange={handleFormUpdate}
                />
              </label>
              <label htmlFor="directDial">
                Direct Dial
                <input
                  type="tel"
                  name="directDial"
                  id="directDial"
                  placeholder="Direct Dial"
                  value={formInfo.directDial}
                  onChange={handleFormUpdate}
                />
              </label>
              <label htmlFor="email">
                Email Address
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email Address"
                  value={formInfo.email}
                  onChange={handleFormUpdate}
                />
              </label>
              <label htmlFor="message">
                Message
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  placeholder="Let us know your goals, pain points, and how we can help. "
                  onChange={handleFormUpdate}
                  value={formInfo.message}
                ></textarea>
              </label>
              <input type="hidden" name="form-name" value="tiex" />
              <button type="submit">
                GET MOVING
                <img aria-hidden="true" src={rightArrowWhite} alt="arrow" />
              </button>
            </form>
          </div>
        </div>
      </section>
      <div className="red-section-end">
        <img src={BottomRedSwoosh} alt="" />
      </div>
      <footer>
        <div className="info">
          <div className="footer-logo">
            Opportunity <span className="yellow">AI</span>
            <sup className="tm">TM</sup>
          </div>
          <p>POWERED BY THE INTELLIGENCE EXCHANGE</p>
        </div>
        <div className="links">
          <p className="human">Talk to a Human</p>
          <a className="number" href="tel:773-839-3820 ">
            773.839.3820
          </a>
        </div>
      </footer>
      <div className="copy">
        <p className="description gray">
          © {new Date().getFullYear()} TIEX, The Intelligence Exchange. All
          Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default App;
